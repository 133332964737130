.milestone-line {
  margin-top: 5rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 1em;
}
.milestone-line__group {
  flex: 0 1 auto;
}
.milestone-line__title {
  border-bottom: 0.2em solid $unik-yellow-light;
  font-weight: bold;
  a {
    color: $black;
  }
}
.milestone-line__weeks {
  margin: 0.5em 0;
  display: flex;
  gap: 0.2em;
}
.milestone-line__week.button {
  font-size: clamp(12px, 1.5vw, 16px);

  width: 2.4em;
  height: 2.4em;
  padding: 0.2em 0 0 0;
  margin: 0;

  background-color: $white;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  cursor: unset;

  &.past {
    opacity: 0.3;
  }

  &.current {
    background-color: $unik-yellow-light;
  }
}

.milestone-list {
  h2 {
    margin-top: 5rem;
    font-size: 1.8rem;
  }
  h3 {
    margin-top: 2rem;
    font-size: inherit;
  }
}
.milestone-list__title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.milestone-list__hints {
  display: grid;
  gap: 0.5em;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  &.fr-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.milestone-list__hint {
  margin: 0;
  text-align: left;

  &--introduction {
    font-weight: bold;
  }

  &--default {
    background-color: $unik-antique;
  }

  &--optional {
    background-color: $white;
  }
}
