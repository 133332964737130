.shipping-address-toggle .columns {
  display: flex;
  gap: 1rem;
  align-items: baseline;
  margin-bottom: 2rem;
  label {
    margin-block: 0 !important;
  }
  div {
    display: flex;
    gap: 1rem;
  }
}

.shipping-address-container {
  border: 1px solid $grey-medium;
  padding: 1rem 1.5rem;
  margin-bottom: 2rem;
}
