/*
* Extension & Global Styles
*/

%box-shadow-blur {
  //box-shadow: 1px 2px 2px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
}

%box-shadow-solid {
  box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.3);
}

.inline-list {
  list-style: none;
  display: block;
  margin: 0;
  .border-bottom {
    border-bottom: thin solid $grey-border;
  }
}

.prepend-top {
  padding-top: $base-line;
}

.append-bottom {
  padding-bottom: $base-line;
}

.margin-right {
  margin-right: $base-line/2;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
  &.right-top {
    right: 0;
    top: 0;
  }
}

.fa-large {
  font-size: rem-calc(20);
}

.p-large {
  font-size: rem-calc(28);
}

button,
.button,
input[type="file"]::-webkit-file-upload-button {
  @extend %box-shadow-solid;
  &.primary {
    &:hover {
      background: darken($unik-yellow-light, 18%);
    }
  }
  &.dark {
    background: $unik-yellow-dark;
    &:hover {
      background: darken($unik-yellow-dark, 3%);
    }
  }
  &.copy-from-note-button {
    padding: rem-calc(10) rem-calc(20);
  }
}

a[class] {
  color: $body-font-color;
}

a.text-link {
  color: $anchor-font-color;
  &:hover {
    opacity: 0.7;
  }
}
a.opacity-link {
  &:hover {
    opacity: 0.7;
  }
}

.mono {
  font-family: $font-family-mono;
}

.serif {
  font-family: $font-family-serif;
}

h1 {
  margin-top: 5rem;
}

.backlink + h1 {
  margin-top: 1rem;
}

body {
  overflow-x: hidden;
}
