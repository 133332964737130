.card {
  @extend %box-shadow-blur;
  background: $white;
  border-radius: 2px;
  border: 1px solid $grey-border;
  display: block;
  margin: 0 0 1rem;
  padding: $base-line;

  li > & {
    height: rem-calc(268);
    margin-bottom: 0; /* :-( */
    p {
      margin-bottom: 0;
    }
    overflow: hidden;
  }

  &.flexbox {
    display: flex;
    padding: 0;
  }

  &.column {
    flex-flow: column;
  }

  .oneline {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.nospacing + .nospacing {
  margin-top: -1rem;
}

.card-fixed {
  flex-shrink: 1;
}

.card-flexible {
  flex-grow: 1;
}

.card-inner {
  padding: $base-line;
  max-width: 100%;
}

.card-image {
  width: rem-calc(200);
  min-width: rem-calc(200);
  // background-color: $grey-light;
  background-position: center center;
  background-repeat: no-repeat;
}

.sortable-tools {
  background-color: $grey-light;
  display: flex;
  flex-flow: column;
  width: rem-calc(40);
  min-width: rem-calc(40);

  &.bottom {
    width: auto;
    width: 100%;
    position: absolute;
    bottom: 0;
    flex-flow: row;
  }
}

.sortable-arrow.arrow-up,
.sortable-arrow.arrow-down,
.sortable-handle {
  display: block;
  padding: 1rem;
  &:hover {
    background-color: darken($grey-light, 5%);
  }
}

.sortable-arrow {
  flex-shrink: 1;
  cursor: pointer;
  background: url(static/assets/icons/sortable-arrow-up.png) center center
    no-repeat;
  background-size: rem-calc(12) auto;
  &.arrow-down {
    background: url(static/assets/icons/sortable-arrow-down.png) center center
      no-repeat;
    background-size: rem-calc(12) auto;
  }
}
.sortable-handle {
  flex-grow: 1;
  cursor: move;
  cursor: -webkit-grabbing;
  background: url(static/assets/icons/sortable-handle.png) center center
    no-repeat;
  background-size: rem-calc(12) auto;
}

.sortable-ghost {
  background: $grey-light;
  opacity: 0.5;
}

.sortable-tools.bottom {
  .sortable-arrow {
    background: url(static/assets/icons/sortable-arrow-left.png) center center
      no-repeat;
    background-size: auto rem-calc(12);
    &.arrow-down {
      background: url(static/assets/icons/sortable-arrow-right.png) center
        center no-repeat;
      background-size: auto rem-calc(12);
    }
  }
  .sortable-handle {
    background: url(static/assets/icons/sortable-handle-horizontal.png) center
      center no-repeat;
    background-size: auto rem-calc(12);
  }
  .sortable-arrow,
  .sortable-arrow.arrow-down,
  .sortable-handle {
    &:hover {
      background-color: darken($grey-light, 5%);
    }
  }
}
