.cta__container {
  position: absolute;
  top: 0;
  right: 0;

  background-color: $unik-green;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 2em;

  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  // aspect-ratio 1:1
  &::before {
    float: left;
    padding-top: 100%;
    content: "";
  }
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

.cta__content {
  transform: rotate(10deg);
  transform-origin: left 50%;
  text-align: center;
  line-height: 1.3;
  font-size: 1.2rem;

  // reset to use rte
  p {
    line-height: inherit;
    font-size: inherit;
    margin: 0;
  }
}

.cta__wrapper {
  margin-top: rem-calc(100);
  position: absolute;
  z-index: 1; // ref. footer.scss
  width: 100%;
  bottom: rem-calc(80);
}

.cta--home {
  transform: translateX(50%) translateY(calc(-40%));
  justify-content: left;
  align-items: center;
}

.cta--home .cta__content {
  text-align: left;
  max-width: 33%;
  padding-bottom: 40%;
}

.cta__container.cta--home {
  padding: 3em;
}

.content {
  position: relative;
}
