@import "foundation-sites/scss/foundation/functions";

// colors
$unik-yellow-light: #feee35;
$unik-yellow-dark: #f8d500;
$unik-sand: #dbb91a;
$unik-green: #87d096;
$unik-red: #eb695a;
$unik-red-dark: #b62716;
$unik-antique: #fcf8d6;

$grey-light-bg: #f6f6f6;
$grey-light: #e7e7e7;
$grey-border: #d8d8d8;
$grey-medium: #a3a3a3;
$grey-dark: #222222;

$grey: #1d1d1d;
$black: #000;

$alert-color: $unik-green;
$warning-color: $unik-green;
$success-color: $unik-green;
$info-color: $unik-green;

:root {
  --primary: #feee35;
  --sand: #dbb91a;
  --grey: #1d1d1d;
}

$primary-color: $unik-yellow-light;
$body-font-color: $grey-dark;
$anchor-font-color: $unik-sand;
$input-placeholder-font-color: $grey-light;

// font
$font-family-mono: "iAWriterDuoS", "Courier New", Courier, monospace;
$font-family-serif: "WarnockPro", Georgia, Cambria, serif;
$body-font-family: $font-family-serif;
$base-font-size: 104%;
$base-line: 1.5rem;
$small-font-size: 80%;
$input-font-size: 1.3rem;

// topbar
$topbar-height: rem-calc(100);
$topbar-font-size: rem-calc(16);
$topbar-link-font-size: rem-calc(16);
$topbar-bg-color: transparent;
$topbar-bg: transparent;
$topbar-link-bg: transparent;
$topbar-dropdown-bg: transparent;
$topbar-link-color: $black;

// tabs
$tabs-navigation-padding: 0.5rem;
$tabs-navigation-bg-color: $unik-yellow-dark;
$tabs-navigation-font-size: rem-calc(20);

// progress bar
$progress-bar-height: rem-calc(64);
$progress-bar-border-size: 2px;

// forms
$form-label-small-transform: none;

// breakpoints
$small-breakpoint: em-calc(730);
// $medium-breakpoint: em-calc(1024);
// $large-breakpoint:  em-calc(1440);
// $xlarge-breakpoint: em-calc(1920);

// $reveal-modal-bg: $white;
$reveal-position-top: rem-calc(100);
$reveal-default-width: 80%;
// $reveal-max-width: $row-width;
$reveal-modal-padding: rem-calc(30);
// $reveal-box-shadow: 0 0 10px rgba($black, 0.4);

$reveal-close-font-size: 1rem;
$reveal-close-top: rem-calc(10);
$reveal-close-side: rem-calc(22);
$reveal-close-color: $black;
$reveal-close-weight: normal;

// buttons
$button-font-color: $body-font-color;

// alert-boxes
$alert-padding-top: rem-calc(32);
$alert-font-color: $black;
$alert-font-size: rem-calc(16);
$alert-border-width: 0px;
// $alert-close-position: rem-calc(16);
// $alert-close-color: $black;

// Check out http://foundation.zurb.com/docs/components/kitchen_sink.html
// @import 'foundation-sites/scss/foundation/components/accordion';
@import "foundation-sites/scss/foundation/components/alert-boxes";
@import "foundation-sites/scss/foundation/components/block-grid";
// @import 'foundation-sites/scss/foundation/components/breadcrumbs';
// @import 'foundation-sites/scss/foundation/components/button-groups';
@import "foundation-sites/scss/foundation/components/buttons";
// @import 'foundation-sites/scss/foundation/components/dropdown';
// @import 'foundation-sites/scss/foundation/components/dropdown-buttons';
// @import 'foundation-sites/scss/foundation/components/flex-video';
// @import "foundation-sites/scss/foundation/components/forms";
@import "foundation-sites/scss/foundation/components/grid";
// @import 'foundation-sites/scss/foundation/components/inline-lists';
// @import 'foundation-sites/scss/foundation/components/joyride';
// @import 'foundation-sites/scss/foundation/components/keystrokes';
// @import 'foundation-sites/scss/foundation/components/labels';
// @import 'foundation-sites/scss/foundation/components/magellan';
// @import 'foundation-sites/scss/foundation/components/pagination';
// @import 'foundation-sites/scss/foundation/components/panels';
// @import 'foundation-sites/scss/foundation/components/pricing-tables';
// @import "foundation-sites/scss/foundation/components/progress-bars";
@import "foundation-sites/scss/foundation/components/reveal";
// @import 'foundation-sites/scss/foundation/components/side-nav';
// @import 'foundation-sites/scss/foundation/components/split-buttons';
// @import 'foundation-sites/scss/foundation/components/sub-nav';
// @import 'foundation-sites/scss/foundation/components/switches';
@import "foundation-sites/scss/foundation/components/tables";
@import "foundation-sites/scss/foundation/components/tabs";
// @import 'foundation-sites/scss/foundation/components/thumbs';
// @import 'foundation-sites/scss/foundation/components/tooltips';
@import "foundation-sites/scss/foundation/components/top-bar";
@import "foundation-sites/scss/foundation/components/type";
// @import 'foundation-sites/scss/foundation/components/offcanvas';
@import "foundation-sites/scss/foundation/components/visibility";

@import "./scss/fonts";
@import "./scss/extension";
@import "./scss/header";
@import "./scss/footer";
@import "./scss/editor";
@import "./scss/cards";
@import "./scss/hints";
@import "./scss/home";
@import "./scss/forms";
@import "./scss/printing";
@import "./scss/spinner";
@import "./scss/reveal";
@import "./scss/icons";
@import "./scss/buttons";
@import "./scss/controls";
@import "./scss/covers";
@import "./scss/cta";
@import "./scss/alert-boxes";
@import "./scss/content";
@import "./scss/proofreading";
@import "./scss/order";

.content {
  background: linear-gradient(
    to bottom,
    $unik-yellow-light,
    $grey-light-bg 24rem
  );
  min-height: 25rem;
  padding: $base-line * 3 0;
}

.book-header {
  background: linear-gradient(
    to bottom,
    $unik-yellow-light,
    $grey-light-bg 24rem
  );
}

.content.book {
  background: $grey-light-bg;
}

.tabs {
  /* I hate frameworks. Hack foundation's tabs. */
  max-width: rem-calc(800);
  line-height: 1;
  .tab-title {
    width: calc(33% - 1rem);
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
    a {
      padding: 1.3em 1.5em 1.1em;
      // @media #{$medium-up} {
      //   padding: 1.5em 1.5em 1.3em;
      // }
      &.active {
        background-color: $grey-light-bg;
      }
    }
  }
}

/* more hacks */
.top-bar .toggle-topbar.menu-icon a {
  color: $black;
}
.top-bar .toggle-topbar.menu-icon a span::after {
  box-shadow:
    0 0 0 1px $black,
    0 7px 0 1px $black,
    0 14px 0 1px $black;
}

.hint {
  margin-bottom: 3rem;
}

// reset foundation type
small abbr[title] {
  font-size: 100%;
  cursor: unset;
  color: $unik-sand;
  text-decoration: none;
  border-bottom: none;
}
