/*
* Forms
*/

// General form styles
form {
  transition: filter 1s ease-in-out;

  &.filter {
    -webkit-filter: sepia(90%) hue-rotate(60deg) blur(1px);
    filter: sepia(90%) hue-rotate(60deg) blur(1px);
  }
}

input.large,
input:not([type]),
input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="color"],
textarea,
select {
  height: auto;
  font-size: $input-font-size;
  padding: $base-line/1.5 $base-line/2;
  margin-bottom: $base-line;

  label + & {
    margin-top: $base-line/4;
  }
}

select {
  margin: 0;
}

input[type="text"],
textarea {
  font-family: $font-family-mono;
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type="checkbox"],
  input[type="radio"] {
    --active: var(--primary);
    --active-inner: var(--grey);
    --focus: 2px var(--grey);
    --border: var(--sand);
    --border-hover: var(--grey);
    --background: var(--primary);
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 16px;
    width: 16px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));

    &:after {
      content: "";
      opacity: 0;
      display: block;
      position: absolute;
      left: -1px;
      top: -1px;
    }

    &:checked {
      &:after {
        opacity: 1;
      }
    }

    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          --bc: var(--border-hover);
        }
      }
    }
    &:focus {
      box-shadow: 0 0 0 var(--focus);
    }
  }

  input[type="checkbox"] {
    border-radius: 3px;
    &:after {
      width: 5px;
      height: 9px;
      border: 2px solid var(--active-inner);
      border-top: 0;
      border-left: 0;
      left: 5px;
      top: 2px;
      transform: rotate(var(--r, 20deg));
    }
    &:checked {
      --r: 43deg;
    }
  }

  input[type="radio"] {
    border-radius: 50%;
    &:after {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: var(--active-inner);
      transform: scale(0.5);
    }
  }
}

// Form Validation
.alert-box.alert {
  background: transparent;
  margin: 0;
  padding: 0;
  border: none;
  .close,
  strong {
    display: none;
  }
}

.errorlist {
  margin: 0;
  li {
    list-style: none;
    background: rgba($alert-color, 0.15);
    color: $black;
    padding: $base-line/2 $base-line;
    font-size: $small-font-size;
    margin: $base-line 0 $base-line/2;
  }
  & + p > input {
    border-color: rgba($alert-color, 0.8);
    background: rgba($alert-color, 0.03);
  }
}

.error label {
  color: $black;
}
.error {
  small.error {
    list-style: none;
    background: rgba($alert-color, 0.15);
    color: $black;
    padding: $base-line/2 $base-line;
    margin: -$base-line/2 0 $base-line;
    font-style: normal;
  }
}

#id_cover_color {
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  margin: 0.375rem 0 0 0;
  padding: 0;
  gap: 2rem;

  label {
    position: relative;
    overflow: hidden;
    text-align: center;
    overflow: visible;
  }

  input {
    position: absolute;
    // top: -30px;
    opacity: 0;
  }

  input + span {
    display: block;
    width: 7rem;
    height: 9rem;
    margin-bottom: 0.5rem;
  }

  input:checked + span {
    box-shadow: 1px 1px 7px 1px rgba(30, 30, 60, 0.8);
  }
}

#id_language {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  label {
    margin: 0 1rem 0 0;
  }
}

.errorlist + p {
  input,
  textarea {
    border-color: red;
  }
}

.circle {
  display: inline-block;
  border-radius: 99px;
  width: 1.5rem;
  height: 1.5rem;
  vertical-align: middle;
  margin-right: 0.2em;
  transform: translateY(-0.2em);
}

.with-buttons {
  margin-top: 3.2rem;
  display: flex;
  align-items: center;

  .buttons {
    margin: 0 0 0 auto;
    display: flex;
    gap: rem-calc(10);
    .button {
      margin: 0;
    }
  }
}

.helptext {
  display: block;
  font-size: 75%;
  color: #444;
  a {
    text-decoration: underline;
  }
}

.order-form {
  label[for="id_terms"] {
    max-width: 85%;
    vertical-align: top;
  }

  label[for="id_zip_code"] {
    text-transform: uppercase; /* Why??? */
  }

  select {
    margin-bottom: $base-line;
  }
}

.search-form {
  display: inline-block;
  transform: translateY(1px);
  .search-field {
    position: relative;

    input {
      padding: rem-calc(17) rem-calc(45) rem-calc(11) rem-calc(15);
      margin-bottom: 1.25rem;
    }

    .icon {
      position: absolute;
      top: rem-calc(16);
      right: rem-calc(12);
    }
  }
}

.clearable-image-input .label {
  color: #4d4d4d;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.5;
}
.clearable-image-input__preview {
  img {
    width: fit-content;
  }

  &.disabled {
    opacity: 0.5;
  }

  margin-bottom: 0.5rem;
}

.clearable-image-input__delete {
  display: flex;
  gap: 0.5rem;
  input {
    display: none;
  }
  .button.checked {
    background-color: $unik-red-dark;
  }
}

.clearable-image-input__input {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1.5rem;

  input {
    display: none;
  }
  button {
    margin-bottom: 0;

    &[disabled] {
      opacity: 0.5;
    }
  }
  .label {
    display: inline-block;
  }
}

.finish-book {
  margin-top: 2.2rem;
}

.order-form {
  input {
    font-size: 1rem;
  }
}

.row.order-form__article-table {
  margin-block: 1rem;
  input {
    margin-bottom: 0;
  }
  .row {
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
  }
}

// reset form inlines
form {
  label:not(:last-of-type) {
    margin-top: 1rem;
  }

  label > input[type="checkbox"] {
    display: block;
  }

  input[type="checkbox"] + label,
  input[type="radio"] + label {
    display: block;
    margin-left: unset;
    margin-right: unset;
    &:not(:last-of-type) {
      margin-top: 1rem;
    }
  }

  .columns {
    input[type="checkbox"] + label,
    input[type="radio"] + label {
      display: inline-block;
      margin-left: 0.5rem;
    }
  }
}

label small,
label + small {
  float: right;
  margin-top: 0.2rem;
}
