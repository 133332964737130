$colors: (orange, #f1901a), (turquois, #69c1bd), (lilac, #bbb5d9),
  (salmon, #f8c4b0), (bluegrey, #a4c8e1), (ocher, #e1be4f), (grey, #cfd0d0);

@each $name, $color in $colors {
  .#{$name} {
    background-color: $color;
  }
}

.cover {
  width: 9rem;
  height: 12rem;
  padding: 1rem 1rem;
  text-align: center;
  margin: auto;

  display: flex;
  flex-flow: column;

  .cover-title {
    font-size: 0.8rem;
    display: block;
  }
  .cover-author {
    font-size: 0.5rem;
  }
  .cover-edition {
    margin-top: auto;
    font-size: 0.33rem;
  }
}

.order-form .cover {
  margin-left: 0;
  margin-bottom: 0.5em;
}
