#editable {
  background: #fff;
}
.ProseMirror {
  /* Re-create the input styling */
  border: 1px solid #cccccc !important; /* Override .ProseMirror inline style */
  box-shadow: inset 0 1px 2px 0px rgba(0, 0, 0, 0.1);
  transition:
    border-color 0.15s linear,
    background 0.15s linear;
  outline: none;
  margin-bottom: $form-spacing;
  padding: $base-line/2;
  color: rgba(0, 0, 0, 0.75);
  cursor: text;

  &:focus {
    background-color: scale-color($white, $lightness: -2%);
  }

  p,
  ul,
  ol {
    font-family: $font-family-mono;
    font-size: $input-font-size;
  }

  p {
    margin: 0;
  }

  // clearpage {
  //   display: block;
  //   width: 100%;
  //   margin: 1rem 0;
  //   border-top: 1px solid #ccc;
  // }
}

.ProseMirror-menubar {
  min-height: 0 !important; /* Override some brokenness in prosemirror-menu's code */
  padding: $base-line/4 !important;
  padding-bottom: 0 !important;
  border-bottom: none !important;
}

.ProseMirror-menuitem {
  font-size: 1.25rem;
}

// prettier-ignore
// .ProseMirror-menuitem:nth-child(4), // insert
// .ProseMirror-menuitem:nth-child(5), // type
// .ProseMirror-menuitem:nth-child(10), // join block
// .ProseMirror-menuitem:nth-child(11), // lift out of block
// .ProseMirror-menuitem:nth-child(12), // select parent node
// .ProseMirror-menuseparator {
//   display: none !important;
// }

.ProseMirror-menuitem:nth-child(4),
.ProseMirror-menuitem:nth-child(5) {
  float: right;
}

.autosave-message {
  position: fixed;
  width: 100%;
  background: rgba(250, 170, 70, 1);
  box-shadow: 0 0 2px 0 rgba(30, 30, 60, 0.8);
  color: #222;
  z-index: 9999;
  text-align: center;
  padding: 0.5em 0;

  transition: top 0.5s ease-out;
  top: -100px;

  .warning,
  .fail {
    display: none;
  }

  &.show-failed,
  &.show-warning {
    top: 0px;
  }

  &.show-warning .warning {
    display: block;
  }

  &.show-failed .fail {
    display: block;
  }
}
