.header {
  @include clearfix;

  background-color: $unik-yellow-light;

  .name > h1 > a {
    font-size: 1.5rem;
    padding: 0 1rem;
  }

  .nav-container {
    border-bottom: 2px solid $white;
  }
}

#main-nav {
  li {
    @media #{$medium-up} {
      border-top: none;
    }
    a {
      padding: 1rem;
      @media #{$medium-up} {
        border-bottom: none;
        padding: 0 1rem;
      }
      @media #{$large-up} {
        padding: 0 2rem;
      }
      &:hover,
      &.active {
        background-color: unset;
        text-decoration: underline;
        color: $black;
      }
    }
  }
}
