.footer {
  padding: 1rem 0 5rem;

  position: relative;
  z-index: 2; // ref. cta.scss
  background-color: $white;

  .copyright {
    display: flex;
    align-items: center;
    p {
      margin-bottom: 0;
    }
  }

  .row {
    margin-top: 3rem;
  }

  .edition-unik-logo {
    max-width: 6rem;
  }
}
