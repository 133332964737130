@font-face {
  font-family: "WarnockPro";
  font-weight: normal;
  font-style: normal;
  src:
    local("WarnockPro-Regular"),
    url(static/assets/fonts/WarnockPro-Regular.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "WarnockPro";
  font-weight: bold;
  font-style: normal;
  src:
    local("WarnockPro-Bold"),
    url(static/assets/fonts/WarnockPro-Bold.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "WarnockPro";
  font-weight: bold;
  font-style: italic;
  src:
    local("WarnockPro-BoldIt"),
    url(static/assets/fonts/WarnockPro-BoldIt.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "WarnockPro";
  font-weight: normal;
  font-style: italic;
  src:
    local("WarnockPro-It"),
    url(static/assets/fonts/WarnockPro-It.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "iAWriterDuoS";
  font-weight: normal;
  font-style: normal;
  src:
    local("iAWriterDuoS-Regular"),
    url(static/assets/fonts/iAWriterDuoS-Regular.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "iAWriterDuoS";
  font-weight: bold;
  font-style: normal;
  src:
    local("iAWriterDuoS-Bold"),
    url(static/assets/fonts/iAWriterDuoS-Bold.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "iAWriterDuoS";
  font-weight: bold;
  font-style: italic;
  src:
    local("iAWriterDuoS-BoldItalic"),
    url(static/assets/fonts/iAWriterDuoS-BoldItalic.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "iAWriterDuoS";
  font-weight: normal;
  font-style: italic;
  src:
    local("iAWriterDuoS-Italic"),
    url(static/assets/fonts/iAWriterDuoS-Italic.woff2) format("woff2");
  font-display: swap;
}
